import React from 'react';

export const Logo = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="30.000000pt" height="30.000000pt" viewBox="0 0 130.000000 130.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
fill="rgba(255, 255, 255, .75)" stroke="none">
<path d="M1113 1252 c-87 -81 -89 -194 -5 -324 51 -79 65 -133 57 -212 l-6
-61 44 46 c83 85 83 191 1 316 -54 82 -68 130 -60 206 3 31 4 57 2 57 -2 0
-17 -12 -33 -28z"/>
<path d="M903 1206 c-31 -39 -29 -88 3 -144 15 -25 30 -68 33 -96 l7 -51 22
35 c29 46 28 84 -5 139 -16 26 -30 68 -33 96 l-5 50 -22 -29z"/>
<path d="M522 991 c-48 -44 -181 -174 -296 -289 -186 -187 -209 -214 -209
-243 0 -38 40 -79 79 -79 14 0 41 15 64 36 l40 36 0 -226 0 -226 385 0 c358 0
386 1 393 18 13 32 8 176 -8 202 -14 24 -15 21 -18 -53 l-3 -78 -92 3 -92 3
-3 133 -3 132 -129 0 -129 0 -3 -132 -3 -133 -92 -3 -93 -3 0 239 0 238 160
153 160 153 92 -86 91 -86 24 42 c14 24 34 53 46 66 l21 23 -89 90 c-101 103
-159 149 -187 149 -10 0 -58 -36 -106 -79z"/>
<path d="M979 827 c-90 -68 -123 -203 -79 -319 12 -32 42 -85 67 -120 93 -126
116 -205 94 -319 -7 -33 -10 -63 -8 -65 10 -11 94 66 122 111 28 45 30 55 30
144 0 85 -3 102 -28 149 -15 30 -47 79 -71 110 -69 89 -80 117 -81 202 0 41 4
90 8 108 11 41 0 41 -54 -1z"/>
</g>
</svg>
  )
}

